import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationsSelector } from "../selectors";
import { useSnackbar } from "notistack";
import {
    dismissNotificationAction,
    removeNotificationAction,
} from "../actions";
import Button from "@material-ui/core/Button";

export const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(notificationsSelector);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        notifications.forEach(({ key, message, severity, dismissed }) => {
            if (dismissed) {
                closeSnackbar(key);
                return;
            }

            enqueueSnackbar(message, {
                key,
                variant: severity,
                onExited: (event, key) => {
                    dispatch(removeNotificationAction({ key: key as number }));
                },
                action: (key: number) => (
                    <Button
                        data-testid={`dismiss-me-${key}`}
                        onClick={() =>
                            dispatch(dismissNotificationAction({ key }))
                        }
                    >
                        dismiss me
                    </Button>
                ),
            });
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};
