import { MutableRefObject, useRef, useState } from "react";

export interface Scroller {
    x: number;
    y: number;
    setX: (x: number) => void;
    setY: (y: number) => void;
    ref: MutableRefObject<HTMLElement | undefined>;
    update: () => void;
}

export const useScroller = (): Scroller => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const ref = useRef<HTMLElement>();

    return {
        x,
        y,
        setX: (x: number) => setX(x),
        setY: (y: number) => setY(y),
        ref,
        update: () => {
            // had to wrap in a setTimeout for a one single ms. Otherwise, it doesn't work
            const scrollable = ref.current;
            scrollable &&
                scrollable.scrollTo &&
                setTimeout(() => {
                    scrollable.scrollTo({ left: x, top: y });
                }, 1);
        },
    };
};
