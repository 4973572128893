import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import React, { FC } from "react";
import classNames from "classnames";

const useStyles = makeStyles(() =>
    createStyles({
        statusLabel: {
            cursor: "default",
            height: "20px",
            display: "inline-flex",
            padding: "4px 8px",
            flexGrow: 0,
            fontSize: "0.75rem",
            minWidth: "100px",
            alignItems: "center",
            flexShrink: 0,
            fontWeight: 500,
            whiteSpace: "nowrap",
            borderRadius: "2px",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            justifyContent: "center",
        },
        default: {
            color: "#888",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
        info: {
            color: "#3949ab",
            backgroundColor: "rgba(57, 73, 171, 0.08)",
        },
        success: {
            color: "#4caf50",
            backgroundColor: "rgba(76, 175, 80, 0.08)",
        },
        error: {
            color: "#f44336",
            backgroundColor: "rgba(244, 67, 54, 0.08)",
        },
        warning: {
            color: "#ff9800",
            backgroundColor: "rgba(255, 152, 0, 0.08)",
        },
    })
);

export interface Props {
    severity?: 'default'|'info'|'success'|'error'|'warning';
}

export const SeverityLabel: FC<Props> = ({ severity = "default", children }) => {
    const classes = useStyles();
    const labelClasses = classNames(classes.statusLabel, classes[severity]);

    return <span className={labelClasses}>{children}</span>;
};
