import { PDFDocument, PDFPage, PDFPageDrawImageOptions } from 'pdf-lib'
import { POSITION } from '../components/choose-signature/button'

const createDrawImageOptions = (
    page: PDFPage,
    position: POSITION,
    signatureWidth: number,
    signatureHeight: number,
): PDFPageDrawImageOptions => {
    const margin = 10
    const options: PDFPageDrawImageOptions = {
        x: 0,
        y: 0,
        width: signatureWidth,
        height: signatureHeight,
    }

    switch (position) {
        case POSITION.LEFT_TOP:
        case POSITION.LEFT_CENTER:
        case POSITION.LEFT_BOTTOM:
            options.x = margin
            break
        case POSITION.CENTER_TOP:
        case POSITION.CENTER:
        case POSITION.CENTER_BOTTOM:
            options.x = (page.getWidth() - signatureWidth) / 2
            break
        case POSITION.RIGHT_TOP:
        case POSITION.RIGHT_CENTER:
        case POSITION.RIGHT_BOTTOM:
            options.x = page.getWidth() - signatureWidth
            break
    }

    switch (position) {
        case POSITION.LEFT_TOP:
        case POSITION.CENTER_TOP:
        case POSITION.RIGHT_TOP:
            options.y = page.getHeight() - margin - signatureHeight
            break
        case POSITION.LEFT_CENTER:
        case POSITION.CENTER:
        case POSITION.RIGHT_CENTER:
            options.y = (page.getHeight() - signatureHeight) / 2
            break
        case POSITION.LEFT_BOTTOM:
        case POSITION.CENTER_BOTTOM:
        case POSITION.RIGHT_BOTTOM:
            options.y = margin
            break
    }

    return options
}

export const applySignaturesOnPdf = async (
    pdf64: string,
    signature64: string,
    initials64: string,
    signaturePosition: POSITION,
    initialsPosition: POSITION,
) => {
    const signatureWidthInPixels = 190
    const signatureHeightInPixels = 100
    const initialsWidthInPixels = 48
    const initialsHeightInPixels = 25

    const pdfDoc = await PDFDocument.load(pdf64)
    const lastPage = pdfDoc.getPage(pdfDoc.getPageCount() - 1)

    if (signature64) {
        const signaturePng = await pdfDoc.embedPng(signature64)
        const options = createDrawImageOptions(
            lastPage,
            signaturePosition,
            signatureWidthInPixels,
            signatureHeightInPixels,
        )

        // add signature on last page
        lastPage.drawImage(signaturePng, options)
    }

    if (initials64) {
        const initialsPng = await pdfDoc.embedPng(initials64)

        // add initials on each page
        const pages = pdfDoc.getPages()
        pages.forEach((page: PDFPage) => {
            const options = createDrawImageOptions(
                page,
                initialsPosition,
                initialsWidthInPixels,
                initialsHeightInPixels,
            )
            page.drawImage(initialsPng, options)
        })
    }

    const pdf64Result = await pdfDoc.saveAsBase64()
    return `data:application/pdf;base64,${pdf64Result}`
}
