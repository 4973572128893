import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'
import SvgSignaturePrimary from '../icons/images/signature-steven-rosato-primary.svg'
import SvgSignatureSecondary from '../icons/images/signature-steven-rosato-secondary.svg'
import classNames from 'classnames'

export const PositionUnselected = SvgSignaturePrimary
export const PositionSelected = SvgSignatureSecondary;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            position: "absolute",
            width: "33%",
            height: "33%",
            background: `url(${PositionUnselected})`,
            backgroundSize: "60%",
            backgroundRepeat: "no-repeat",
            opacity: "0.2",
            cursor: "pointer",
            '&:hover': {
                opacity: "1",
                border: "3px dotted black",
            }
        },
        selected: {
            background: `url(${PositionSelected})`,
            backgroundSize: "60%",
            backgroundRepeat: "no-repeat",
            border: `3px solid ${theme.palette.primary.main}`,
            opacity: "1",
            cursor: "auto",
        },
    })
);

export enum POSITION {
    LEFT_TOP = "leftTop",
    CENTER_TOP = "centerTop",
    RIGHT_TOP = "rightTop",
    LEFT_CENTER = "leftCenter",
    CENTER = "center",
    RIGHT_CENTER = "rightCenter",
    LEFT_BOTTOM = "leftBottom",
    CENTER_BOTTOM = "centerBottom",
    RIGHT_BOTTOM = "rightBottom",
}

interface Props {
    position: POSITION;
    setPosition: (position: POSITION) => void;
    selected?: boolean;
}

export const Button: FC<Props> = ({
    position,
    selected = false,
    setPosition,
}) => {
    const classes = useStyles();

    const leftPosition = (position: POSITION) => {
        switch (position) {
            case POSITION.LEFT_TOP:
            case POSITION.LEFT_CENTER:
            case POSITION.LEFT_BOTTOM:
                return "0";
            case POSITION.CENTER_TOP:
            case POSITION.CENTER:
            case POSITION.CENTER_BOTTOM:
                return "33%";
            case POSITION.RIGHT_TOP:
            case POSITION.RIGHT_CENTER:
            case POSITION.RIGHT_BOTTOM:
                return "67%";
        }
    };
    const topPosition = (position: POSITION) => {
        switch (position) {
            case POSITION.LEFT_TOP:
            case POSITION.CENTER_TOP:
            case POSITION.RIGHT_TOP:
                return "0";
            case POSITION.LEFT_CENTER:
            case POSITION.CENTER:
            case POSITION.RIGHT_CENTER:
                return "33%";
            case POSITION.LEFT_BOTTOM:
            case POSITION.CENTER_BOTTOM:
            case POSITION.RIGHT_BOTTOM:
                return "67%";
        }
    };
    const backgroundPosition = (position: POSITION) => {
        switch (position) {
            case POSITION.LEFT_TOP:
                return "left 5px top 5px";
            case POSITION.CENTER_TOP:
                return "center top 5px";
            case POSITION.RIGHT_TOP:
                return "right 5px top 5px";
            case POSITION.LEFT_CENTER:
                return "left 5px center";
            case POSITION.CENTER:
                return "center center";
            case POSITION.RIGHT_CENTER:
                return "right 5px center";
            case POSITION.LEFT_BOTTOM:
                return "left 5px bottom 5px";
            case POSITION.CENTER_BOTTOM:
                return "center bottom 5px";
            case POSITION.RIGHT_BOTTOM:
                return "right 5px bottom 5px";
        }
    };

    const className = classNames(
        classes.button,
        { [classes.selected]: selected },
        // { [classes.hovered]: hover && !selected }
    );

    return (
        <button
            className={className}
            onClick={() => setPosition(position)}
            title={`Position ${position}`}
            disabled={selected}
            style={{
                color: selected ? "red" : "blue",
                left: leftPosition(position),
                top: topPosition(position),
                backgroundPosition: backgroundPosition(position),
            }}
        />
    );
};
