import { createReducer } from "@reduxjs/toolkit";
import { rememberSignatureAction, signaturePositionAction } from "../actions";
import { Signature } from "../interfaces";
import { POSITION } from "../../../components/choose-signature/button";

export const signatureReducer = createReducer<Signature>(
    { dataUrl: null, remember: true, position: POSITION.RIGHT_BOTTOM },
    (builder) => {
        builder
            .addCase(rememberSignatureAction, (state, action) => {
                state.remember = action.payload;
            })
            .addCase(signaturePositionAction, (state, action) => {
                state.position = action.payload;
            });
    }
);
