import SvgLayout from '../icons/images/choose-signature-layout.svg'
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { Button, POSITION } from './button'

const scale = 1.4
const width = 260 * scale;
const height = 368 * scale;
const gutter = 5 * scale;

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            width: `${width}px`,
            height: `${height}px`,
            margin: "0 auto",
            position: "relative",
            backgroundImage: `url(${SvgLayout})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
        },
        buttons: {
            width: `${width - gutter * 2}px`,
            height: `${height - gutter * 2}px`,
            margin: "0 auto",
            position: "absolute",
            left: `${gutter}px`,
            top: `${gutter}px`,
        },
    })
);

interface Props {
    currentPosition: string;
    onPositionChange: (position: POSITION) => void;
}

export const ChooseSignaturePosition: FC<Props> = ({
    currentPosition,
    onPositionChange,
}) => {
    const classes = useStyles();
    const positions = [
        POSITION.LEFT_TOP,
        POSITION.CENTER_TOP,
        POSITION.RIGHT_TOP,
        POSITION.LEFT_CENTER,
        POSITION.RIGHT_CENTER,
        POSITION.LEFT_BOTTOM,
        POSITION.CENTER_BOTTOM,
        POSITION.RIGHT_BOTTOM,
    ];

    return (
        <div className={classes.wrapper}>
            <div className={classes.buttons}>
                {positions.map((position, index) => (
                    <Button
                        key={index}
                        position={position}
                        selected={position === currentPosition}
                        setPosition={onPositionChange}
                    />
                ))}
            </div>
        </div>
    );
};
