import React, { StrictMode } from "react";
import { render } from "react-dom";
import "./api-server";
import App from "./layout/app";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { initStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { Notifications } from "./layout/notifications";

render(
    <StrictMode>
        <Provider store={initStore()}>
            <Notifications>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Notifications>
        </Provider>
    </StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
