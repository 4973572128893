import React from "react";
import { Notifier } from "../modules/notifications/views";
import GlobalStyles from "../components/global-styles";
import theme from "../themes/majisti";
import { ThemeProvider } from "@material-ui/styles";
import { useRoutes } from "react-router-dom";
import routes from "../routes";

const App = () => {
    const routing = useRoutes(routes);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Notifier />
            {routing}
        </ThemeProvider>
    );
};

export default App;
