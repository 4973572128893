import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import {
    approveAction,
    approveSuccessAction,
    beginGenerateDocumentAction,
    endGeneratedDocumentAction,
    generatedDocumentSelector,
    loadApprovalAction,
    loadApprovalSuccessAction,
    requestApprovalAction,
    requestApprovalsAction,
    requestApprovalsSuccessAction,
    requestApprovalSuccessAction,
    resetSelectedApprovalAction,
    selectedApprovalIdSelector,
    selectedApprovalSelector,
    setOriginalDocumentAction,
    viewGeneratedDocumentAction,
} from '../../modules/approval'
import { Approval } from '../../modules/approval/interfaces'
import { getApproval, getApprovals, putApprove } from '../../connectivity/api'
import { notifyErrorAction, notifySuccessAction } from '../../modules/notifications'

function* fetchApproval(action: ReturnType<typeof requestApprovalAction>) {
    const approvalId = action.payload

    try {
        yield put(requestApprovalSuccessAction(yield call(getApproval, approvalId)))
    } catch (error) {
        yield put(notifyErrorAction({ message: error.message }))
    }
}

function* loadApproval(action: ReturnType<typeof loadApprovalAction>) {
    const approvalId = action.payload

    yield put(requestApprovalAction(approvalId))
    const approvalSuccessAction: ReturnType<typeof requestApprovalSuccessAction> = yield take(
        requestApprovalSuccessAction.type,
    )

    yield put(loadApprovalSuccessAction(approvalSuccessAction.payload.id))

    const originalDocument = ((yield select(selectedApprovalSelector)) as Approval).originalDocument

    if (originalDocument?.uri) {
        yield put(setOriginalDocumentAction(originalDocument.uri))
    }
}

function* fetchApprovals() {
    try {
        yield put(requestApprovalsSuccessAction(yield call(getApprovals)))
    } catch (error) {
        yield put(notifyErrorAction({ message: error.message }))
    }
}

function* viewSignedDocument(action: ReturnType<typeof viewGeneratedDocumentAction>) {
    yield put(beginGenerateDocumentAction())
    const approval: Approval = yield call(getApproval, action.payload)
    yield put(endGeneratedDocumentAction(approval.signedDocument?.uri!))
}

function* approve() {
    try {
        yield put(
            approveSuccessAction(
                yield call(
                    putApprove,
                    yield select(selectedApprovalIdSelector),
                    yield select(generatedDocumentSelector),
                ),
            ),
        )
        yield put(notifySuccessAction({ message: 'Document was approved successfully' }))
        yield put(resetSelectedApprovalAction())
    } catch (error) {
        yield put(notifyErrorAction({ message: error.message }))
    }
}

export function* watchApprovalSaga() {
    yield fork(function* () {
        yield takeEvery(requestApprovalAction.type, fetchApproval)
    })
    yield fork(function* () {
        yield takeEvery(requestApprovalsAction.type, fetchApprovals)
    })
    yield fork(function* () {
        yield takeEvery(loadApprovalAction.type, loadApproval)
    })
    yield fork(function* () {
        yield takeEvery(approveAction.type, approve)
    })
    yield fork(function* () {
        yield takeEvery(viewGeneratedDocumentAction.type, viewSignedDocument)
    })
}
