import {
    beginGenerateDocumentAction,
    clearGeneratedDocumentAction,
    endGeneratedDocumentAction,
    endInitialsAction,
    endSignatureAction,
    requestApprovalAction,
    requestApprovalSuccessAction,
    resetSignaturePadAction,
    setOriginalDocumentAction,
    startSigningAction,
} from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { SignaturePadState } from "../../../redux/state";

const initialState: SignaturePadState = {
    originalDocument: "",
    generatedDocument: "",
    documentIsGenerating: false,
    userIsSigning: false,
    signature: "",
    initials: "",
};
export const signaturePadReducer = createReducer<SignaturePadState>(
    initialState,
    (builder) => {
        builder
            .addCase(startSigningAction, (state) => {
                state.userIsSigning = true;
            })
            .addCase(setOriginalDocumentAction, (state, action) => {
                state.originalDocument = action.payload;
            })
            .addCase(beginGenerateDocumentAction, (state) => {
                state.documentIsGenerating = true;
            })
            .addCase(requestApprovalAction, (state) => {
                state.documentIsGenerating = true;
            })
            .addCase(requestApprovalSuccessAction, (state) => {
                state.documentIsGenerating = false;
            })
            .addCase(endGeneratedDocumentAction, (state, action) => {
                state.generatedDocument = action.payload;
                state.documentIsGenerating = false;
                state.userIsSigning = false;
            })
            .addCase(clearGeneratedDocumentAction, (state, action) => {
                state.generatedDocument = "";
            })
            .addCase(endSignatureAction, (state, action) => {
                state.signature = action.payload;
            })
            .addCase(endInitialsAction, (state, action) => {
                state.initials = action.payload;
            })
            .addCase(resetSignaturePadAction, (state) => {
                state.originalDocument = "";
                state.generatedDocument = "";
                state.signature = null;
                state.initials = null;
            });
    }
);
