export interface Config {
    signatureDebounceThresholdMs: number, //this will limit generating the document to many times when the user "lifts" is stroke when signing
    notifications: {
        displayDurationMs: number,
        enterDurationMs: number | undefined,
        exitDurationMs: number | undefined,
    }
}

export const AppConfig: Config = {
    signatureDebounceThresholdMs: 800,
    notifications: {
        displayDurationMs: 5000,
        enterDurationMs: undefined,
        exitDurationMs: undefined,
    }
}
