import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { AppBar, makeStyles, Toolbar, Typography } from "@material-ui/core";
import ApplicationLogo from "../../themes/majisti/logo-muffin.svg";

const useStyles = makeStyles({
    root: {},
    toolbar: {
        height: 70,
    },
    homeLink: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        marginTop: "5px",
    },
    subtitle: {
        marginTop: "6px",
        marginLeft: "20px",
    },
});

interface Props {
    className: string;
}

const TopBar: FC<Props> = ({ className, ...rest }) => {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/" className={classes.homeLink}>
                    <img
                        src={ApplicationLogo}
                        className="logo"
                        alt="Majisti Logo"
                        width="80"
                        height="80"
                    />

                    <Typography
                        className={classes.title}
                        variant="h4"
                        style={{color: "white"}} //fixme: probably need to setup some time of contrasting text with Material UI Theming
                    >
                        Baking Sheet
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        variant="h6"
                        style={{color: "white"}} //fixme: probably need to setup some time of contrasting text with Material UI Theming
                    >
                        Easy & Fast Timesheet Approval System
                    </Typography>
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
