import { initialsPositionAction, rememberInitialsAction } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { Signature } from "../interfaces";
import { POSITION } from "../../../components/choose-signature/button";

export const initialsReducer = createReducer<Signature>(
    { dataUrl: null, remember: true, position: POSITION.LEFT_BOTTOM },
    (builder) => {
        builder
            .addCase(rememberInitialsAction, (state, action) => {
                state.remember = action.payload;
            })
            .addCase(initialsPositionAction, (state, action) => {
                state.position = action.payload;
            });
    }
);
