import { createStyles, Fab, Theme } from "@material-ui/core";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { SeverityLabel } from "../severity-label";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SCALE, Zoomer } from "./zoomer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: "relative",
        },
        buttonsWrapper: {
            position: "absolute",
            top: "5px",
            left: "15px",
        },
        fab: {
            margin: "4px",
        },
        scale: {
            position: "absolute",
            top: "5px",
            right: "30px",
        },
    })
);

interface Props {
    children: any;
    zoomer: Zoomer;
}

export const Zoomable: FC<Props> = ({
    zoomer: { scale, setScale },
    children,
}) => {
    const classes = useStyles();

    const onZoomIn = () => {
        setScale(scale + SCALE.INCREMENT);
    };
    const onZoomOut = () => {
        setScale(scale - SCALE.INCREMENT);
    };

    return (
        <>
            <div className={classes.wrapper}>

                {children}

                <div className={classes.buttonsWrapper}>
                    <Fab
                        title={"Zoom Out"}
                        color={"primary"}
                        size="small"
                        className={classes.fab}
                        onClick={onZoomOut}
                        disabled={scale <= SCALE.MIN}
                    >
                        <ZoomOutIcon />
                    </Fab>
                    <Fab
                        title={"Zoom In"}
                        color={"primary"}
                        size="small"
                        className={classes.fab}
                        onClick={onZoomIn}
                        disabled={scale >= SCALE.MAX}
                    >
                        <ZoomInIcon />
                    </Fab>
                </div>

                <div className={classes.scale} title={"Current scale"}>
                    <SeverityLabel>{scale * 100}%</SeverityLabel>
                </div>
            </div>
        </>
    );
};
