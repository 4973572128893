import React, { FC, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Fab, Paper, Theme, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {default as ChoosePositionIcon} from "@material-ui/icons/Dashboard";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import { ChooseSignaturePosition } from "./choose-signature";
import {POSITION} from "./choose-signature/button";
import classNames from "classnames";

export enum TestIds {
    buttonClearSignature = "clearSignature",
    buttonChoosePosition = "chooseSignaturePosition",
    buttonSignaturePosition = "signaturePosition",
    buttonsWrapper = "buttonsWrapper",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        paperTitle: {
            textAlign: "left",
            padding: "0",
        },
        canvas: {
            border: "1px dashed #888",
            width: "calc(100% - 10px)",
            height: "200px",
        },
        options: {
            border: "1px dashed #888",
            margin: "5px",
            padding: "10px 0",
        },
        formGroup: {
            marginLeft: "0",
        },
        fab: {
            marginLeft: "6px",
            float: "right",
        },
        selectedChoosePosition: {
            color: theme.palette.secondary.main,
        }
    })
);

export interface Props {
    title: string;
    onSignStart?: () => void;
    onSignComplete: (dataUrl: string | null) => void;
    initialSignatureDataUrl?: string | null;
    currentPosition: string;
    onPositionChange: (position: POSITION) => void;
}

export const Signature: FC<Props> = ({
    title,
    onSignStart,
    onSignComplete,
    initialSignatureDataUrl,
    currentPosition,
    onPositionChange,
}) => {
    const classes = useStyles();
    const signatureRef = useRef<ReactSignatureCanvas | null>(null);
    const [currentSignatureDataUrl, setCurrentSignatureDataUrl] = useState(
        initialSignatureDataUrl ?? null
    );
    const [showSignaturePositionView, setShowSignaturePositionView] = useState(false);

    const onDrawFinished = () => {
        const completedSignature = signatureRef.current
            ? signatureRef.current.toDataURL()
            : null;

        let trimmedSignature = signatureRef.current
            ? signatureRef.current.getTrimmedCanvas().toDataURL()
            : null;

        onSignComplete(trimmedSignature);
        setCurrentSignatureDataUrl(completedSignature);
    };

    const clearSignature = () => {
        if (signatureRef.current) {
            signatureRef.current.clear();
            onSignComplete(null);
            setCurrentSignatureDataUrl(null);
            setShowSignaturePositionView(false)
        }
    };

    const toggleShowSignaturePosition = () => {
        setShowSignaturePositionView(!showSignaturePositionView);
    };

    return (
        <Paper className={classes.paper}>
            <Typography
                variant="h4"
                gutterBottom
                className={classes.paperTitle}
            >
                {title}

                <Fab
                    className={classes.fab}
                    color={"primary"}
                    disabled={!currentSignatureDataUrl}
                    size="small"
                    title={"Choose position"}
                    onClick={toggleShowSignaturePosition}
                >
                    <ChoosePositionIcon className={classNames({[classes.selectedChoosePosition]: showSignaturePositionView})}/>
                </Fab>

                <Fab
                    className={classes.fab}
                    color={"primary"}
                    size="small"
                    name="clear"
                    title={"Clear Pad"}
                    onClick={clearSignature}
                    disabled={currentSignatureDataUrl === null}
                    data-testid={TestIds.buttonClearSignature}
                >
                    <DeleteIcon />
                </Fab>
            </Typography>

            {showSignaturePositionView && (
                <div className={classes.options}>
                    <ChooseSignaturePosition
                        currentPosition={currentPosition}
                        onPositionChange={onPositionChange}
                    />
                </div>
            )}

            <SignatureCanvas
                ref={signatureRef}
                onBegin={onSignStart}
                onEnd={onDrawFinished}
                clearOnResize={false}
                canvasProps={{ className: classes.canvas }}
            />
        </Paper>
    );
};
