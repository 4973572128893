import { useState } from "react";

export interface Zoomer {
    scale: number;
    setScale: (scale: number) => void;
}

export const useZoomer = (): Zoomer => {
    const [scale, setScale] = useState(1);

    return {
        scale,
        setScale: (scale: number) => {
            setScale(scale);
        },
    };
};

export enum SCALE {
    INCREMENT = 0.25,
    MIN = 1,
    MAX = 2.5,
}
