import { getApiBaseUrl } from '../env'
import { ApprovalResponse } from './interface'
import { Approval, ApprovalStatus } from '../modules/approval/interfaces'

const createApprovalFromApiResponse = (approvalResponse: ApprovalResponse): Approval => {
    const from = new Date()
    from.setDate(from.getDate() - 7)
    const to = new Date()

    let status
    switch (approvalResponse.status) {
        case ApprovalStatus.new:
            status = ApprovalStatus.new
            break
        case ApprovalStatus.approved:
            status = ApprovalStatus.approved
            break
        default:
            status = ApprovalStatus.unknown
    }

    const approval: Approval = {
        id: approvalResponse['@id'].replace('/api/approvals/', ''),
        name: approvalResponse.name,
        status: status,
        from: from.toDateString(),
        to: to.toDateString(),
    }

    if (approvalResponse.originalDocument) {
        approval.originalDocument = {
            mimeType: approvalResponse.originalDocument.mimeType,
            uri: approvalResponse.originalDocument.uri,
        }
    }

    if (approvalResponse.signedDocument) {
        approval.signedDocument = {
            mimeType: approvalResponse.signedDocument.mimeType,
            uri: approvalResponse.signedDocument.uri,
        }
    }

    return approval
}

export const getApprovals = async (): Promise<Approval[]> => {
    const endpoint = `${getApiBaseUrl()}/approvals`
    const response = await fetch(endpoint)
    const approvalsResponse = await response.json()

    verifyResponse(response)

    // convert to domain data
    const approvals: Approval[] = []

    approvalsResponse['hydra:member'].forEach((approvalResponse: ApprovalResponse) => {
        const approval = createApprovalFromApiResponse(approvalResponse)
        approvals.push(approval)
    })

    return approvals
}

export const getApproval = async (approvalId: string) => {
    const endpoint = `${getApiBaseUrl()}/approvals/${approvalId}`
    const response = await fetch(endpoint)
    const approvalResponse = await response.json()

    verifyResponse(response)

    return createApprovalFromApiResponse(approvalResponse)
}

export const putApprove = async (approvalId: string, signedDocumentUri: string): Promise<Approval> => {
    const endpoint = `${getApiBaseUrl()}/approvals/${approvalId}/approve`
    const response = await fetch(endpoint, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/ld+json' },
        body: JSON.stringify({
            signedDocument: {
                mimeType: 'application/pdf;base64',
                uri: signedDocumentUri,
            },
        }),
    })

    verifyResponse(response)

    return createApprovalFromApiResponse(await response.json())
}

const verifyResponse = (response: Response) => {
    if (!response.ok) {
        if (response.status.toString().startsWith('4')) {
            throw new Error(response.statusText)
        } else {
            throw new Error('An error occurred while approving the document.')
        }
    }
}
