import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import MajistiLogo from "../../themes/majisti/logo.svg";
import HeartIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles(() => ({
    footer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flex: "1 1 auto",
        color: "black",
        marginBottom: "10px",
    },
    heart: {
        color: "#ff0065",
        fontSize: "18px",
        margin: "0 3px",
    },
    majistiLogo: {
        marginLeft: "5px",
    },
    majistiLink: {
        marginLeft: "3px",
    },
}));

export const Footer: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            Made with <HeartIcon className={classes.heart} /> by <a className={classes.majistiLink} href="https://majisti.com">Majisti</a>
            <img
                src={MajistiLogo}
                className={classes.majistiLogo}
                alt="Majisti Logo"
                width="18"
                height="18"
            />
        </div>
    );
};
