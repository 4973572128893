import { createStyles, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, SyntheticEvent } from "react";
import { Scroller } from "./scroller";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            margin: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
            overflow: "auto",
        },
    })
);

interface Props {
    children: any;
    scroller: Scroller;
}

export const Scrollable: FC<Props> = ({
    children,
    scroller: {setX, setY, ref },
}) => {
    const classes = useStyles();

    const onScroll = (e: SyntheticEvent) => {
        const target = e.target as HTMLElement;
        if (target.scrollTop > 0) {
            setY(target.scrollTop);
        }
        if (target.scrollLeft > 0) {
            setX(target.scrollLeft);
        }
    };

    return (
        <Paper className={classes.paper} onScroll={onScroll} ref={ref} style={{padding: "50px"}}>
            {children}
        </Paper>
    );
};
