// Domain
import { POSITION } from '../../components/choose-signature/button'

export interface Approval {
    id: string
    name: string
    status: ApprovalStatus
    from: string
    to: string
    originalDocument?: Document
    signedDocument?: Document
}

export interface Document {
    mimeType: string;
    uri: string;
}

export interface Signature {
    dataUrl: string | null;
    remember: boolean;
    position: POSITION;
}

export enum ApprovalStatus {
    unknown = "unknown",
    new = "new",
    approved = "approved",
}

export enum SignatureType {
    SIGNATURE,
    INITIALS,
}

export interface Paginator {
    numberOfPages: number;
    pageNumber: number;
}
