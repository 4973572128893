import {createReducer} from "@reduxjs/toolkit";
import {
    notifyErrorAction,
    notifyInfoAction,
    addNotificationAction,
    notifySuccessAction,
    notifyWarningAction, dismissNotificationAction,
    removeNotificationAction,
} from "./actions";
import {NotificationSeverity, NotificationState} from "./interfaces";

const generateKey = () => Math.floor(Math.random() * 100000000);

export const notificationsReducer = createReducer<NotificationState>(
    [],
    (builder) => {
        builder
            .addCase(addNotificationAction, (state, action) => {
                state.push(action.payload);
            })
            .addCase(notifySuccessAction, (state, action) => {
                state.push({
                    key: generateKey(),
                    ...action.payload,
                    severity: NotificationSeverity.success,
                });
            })
            .addCase(notifyInfoAction, (state, action) => {
                state.push({
                    key: generateKey(),
                    ...action.payload,
                    severity: NotificationSeverity.info,
                });
            })
            .addCase(notifyWarningAction, (state, action) => {
                state.push({
                    key: generateKey(),
                    ...action.payload,
                    severity: NotificationSeverity.warning,
                });
            })
            .addCase(notifyErrorAction, (state, action) => {
                state.push({
                    key: generateKey(),
                    ...action.payload,
                    severity: NotificationSeverity.error,
                });
            })
            .addCase(removeNotificationAction, (state, action) => {
                const notificationsToKeep = state.filter(
                    (notification) => notification.key !== action.payload.key
                );

                state.splice(0, state.length);
                state.push(...notificationsToKeep);
            })
            .addCase(dismissNotificationAction, (state, action) => {
                //todo: should not remove entire array, should only flag those that are to be dismissed

                const notificationsToDismiss = state.map((notification) =>
                    notification.key === action.payload.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                );

                state.splice(0, state.length);
                state.push(...notificationsToDismiss);
            });
    }
);
