import React, { FC, SetStateAction } from "react";
import { Document, Page } from "react-pdf";
import { createStyles, LinearProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
    documentIsGeneratingSelector,
    generatedDocumentSelector,
    originalDocumentSelector,
} from "../modules/approval";
import { Pager } from "./pagination/pager";
import { usePaginator } from "./pagination/paginator";
import { Draggable } from "./drag/draggable";
import { useDragger } from "./drag/dragger";
import { Zoomable } from "./zoom/zoomable";
import { useZoomer } from "./zoom/zoomer";
import { Scrollable } from "./scroll/scrollable";
import { useScroller } from "./scroll/scroller";

require("react-pdf/dist/umd/pdf.worker.entry.js");
require("pdfjs-dist/es5/build/pdf.js");


export enum TestIds {
    documentPdfLoading = "document-pdf-loading",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        document: {
            maxHeight: "700px",
        },
    })
);

interface PdfDocument {
    numPages: SetStateAction<any>;
}

export const PdfPreview: FC = () => {
    const classes = useStyles();

    const paginator = usePaginator();
    const dragger = useDragger();
    const zoomer = useZoomer();
    const scroller = useScroller();

    const generatedDocument = useSelector(generatedDocumentSelector);
    const originalDocument = useSelector(originalDocumentSelector);
    const documentIsGenerating = useSelector(documentIsGeneratingSelector);

    const onDocumentLoadSuccess = ({ numPages }: PdfDocument) => {
        paginator.setNumberOfPages(numPages);
        scroller.update();
    };

    const pager = paginator.numPages > 1 ? <Pager paginator={paginator} /> : null;

    return (
        <>
            {pager}

            <Zoomable zoomer={zoomer}>
                <Scrollable scroller={scroller}>

                    {documentIsGenerating && (
                        <LinearProgress
                            data-testid={TestIds.documentPdfLoading}
                            color="secondary"
                        />
                    )}

                    {!documentIsGenerating && (
                        <Document
                            file={generatedDocument || originalDocument}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className={classes.document}
                        >
                            <Draggable dragger={dragger}>
                                <Page
                                    pageNumber={paginator.pageNumber}
                                    scale={zoomer.scale}
                                />
                            </Draggable>
                        </Document>
                    )}

                </Scrollable>
            </Zoomable>

            {!documentIsGenerating && pager}
        </>
    );
};
