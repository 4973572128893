import { createAction } from "@reduxjs/toolkit";
import {Approval} from "./interfaces";
import {POSITION} from "../../components/choose-signature/button";

// API
export const requestApprovalsAction = createAction("requestApprovals");
export const requestApprovalsSuccessAction = createAction<Approval[]>("requestApprovalsSuccess");

export const requestApprovalAction = createAction<string>("requestApproval");
export const requestApprovalSuccessAction = createAction<Approval>("fetchApprovalSuccess");

export const approveAction = createAction<string | null>("approve");
export const approveSuccessAction = createAction<Approval>("approveSuccess");

// APP
export const resetSignaturePadAction = createAction("resetSignaturePad");
export const startSigningAction = createAction("startSigning");
export const endSignatureAction = createAction<string | null>(    "endSignatureAction");
export const endInitialsAction = createAction<string | null>(    "endInitialsAction");

export const setOriginalDocumentAction = createAction<string>(    "setOriginalDocument");

export const beginGenerateDocumentAction = createAction(    "beginGenerateDocument");
export const endGeneratedDocumentAction = createAction<string>(    "endGeneratedDocument");
export const viewGeneratedDocumentAction = createAction<string>("viewGeneratedDocument")
export const clearGeneratedDocumentAction = createAction("clearGeneratedDocument")

export const loadApprovalAction = createAction<string>("loadApproval");
export const loadApprovalSuccessAction = createAction<string>("loadApprovalSuccess");
export const resetSelectedApprovalAction = createAction("clearSelectedApproval");

export const paginatorNumberOfPagesAction = createAction<number>("paginatorNumberOfPages");
export const paginatorPageNumberAction = createAction<number>("paginatorPageNumber");
export const paginatorResetAction = createAction("paginatorReset");

// USER
export const rememberSignatureAction = createAction<boolean>("rememberSignature");
export const rememberInitialsAction = createAction<boolean>("rememberInitials");
export const signaturePositionAction = createAction<POSITION>("rememberSignaturePosition");
export const initialsPositionAction = createAction<POSITION>("rememberInitialsPosition");
