import { Approval, ApprovalStatus } from "../interfaces";
import { SeverityLabel } from "../../../components/severity-label";
import React, { FC } from "react";

export const ApprovalStatusLabel: FC<{ approval: Approval }> = ({ approval }) => {
    return (
        <>
            {approval.status === ApprovalStatus.approved && (
                <SeverityLabel severity={"success"}>Approved</SeverityLabel>
            )}
            {approval.status === ApprovalStatus.new && (
                <SeverityLabel severity={"info"}>New</SeverityLabel>
            )}
        </>
    );
};
