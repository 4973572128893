import { createSelector } from 'reselect'
import { ApprovalsByIdState, ApprovalsState, SignaturePadState, State } from '../../redux/state'
import { Approval, Paginator, Signature } from './interfaces'

const userSignatureSelector = (state: State): Signature => state.user.signature
const userInitialsSelector = (state: State): Signature => state.user.initials

const signaturePadSelector = (state: State): SignaturePadState => state.signaturePad

const approvalsStateSelector = (state: State): ApprovalsState => state.approvals
const listedApprovalsStateSelector = (state: State): string[] => state.approvals.listed
const approvalsByIdStateSelector = (state: State): ApprovalsByIdState => state.approvals.byId
const paginatorSelector = (state: State): Paginator => state.paginator

export const userSignatureDataUrlSelector = createSelector([userSignatureSelector], (signature) => signature.dataUrl)
export const userSignatureRememberSelector = createSelector([userSignatureSelector], (signature) => signature.remember)
export const userSignaturePositionSelector = createSelector([userSignatureSelector], (signature) => signature.position)
export const userInitialsDataUrlSelector = createSelector([userInitialsSelector], (initials) => initials.dataUrl)
export const userInitialsRememberSelector = createSelector([userInitialsSelector], (initials) => initials.remember)
export const userInitialsPositionSelector = createSelector([userInitialsSelector], (initials) => initials.position)
export const signatureSelector = createSelector([signaturePadSelector], (signaturePad) => signaturePad.signature)
export const initialsSelector = createSelector([signaturePadSelector], (signaturePad) => signaturePad.initials)

export const originalDocumentSelector = createSelector(
    [signaturePadSelector],
    (signaturePad) => signaturePad.originalDocument,
)
export const generatedDocumentSelector = createSelector(
    [signaturePadSelector],
    (signaturePad) => signaturePad.generatedDocument,
)

export const documentIsGeneratingSelector = createSelector(
    [signaturePadSelector],
    (signaturePad) => signaturePad.documentIsGenerating,
)

export const userIsSigningSelector = createSelector(
    [signaturePadSelector],
    (signaturePad) => signaturePad.userIsSigning,
)

export const selectedApprovalIdSelector = createSelector([approvalsStateSelector], (approvals) => approvals.selected)

export const selectedApprovalSelector = createSelector(
    [approvalsByIdStateSelector, selectedApprovalIdSelector],
    (approvalsById, id) => (id === null ? null : approvalsById[id]),
)

export const listedApprovalsSelector = createSelector(
    [approvalsByIdStateSelector, listedApprovalsStateSelector],
    (approvalsById, listed) => {
        let approvals: Approval[] = []
        listed.forEach((id) => {
            approvals.push(approvalsById[id])
        })

        return approvals
    },
)

export const paginatorNumberOfPagesSelector = createSelector(
    [paginatorSelector],
    (paginator) => paginator.numberOfPages,
)

export const paginatorPageNumberSelector = createSelector([paginatorSelector], (paginator) => paginator.pageNumber)

export const isApprovalReadySelector = createSelector(
    [
        selectedApprovalIdSelector,
        userIsSigningSelector,
        documentIsGeneratingSelector,
        signatureSelector,
        initialsSelector,
        paginatorNumberOfPagesSelector,
    ],
    (
        selectedApprovalId,
        userIsSigning,
        documentIsGenerating,
        signatureDataUrl,
        initialsDataUrl,
        paginatorNumberOfPages,
    ) =>
        selectedApprovalId &&
        !userIsSigning &&
        !documentIsGenerating &&
        !!(signatureDataUrl && (initialsDataUrl || paginatorNumberOfPages <= 1)),
)
