import React, { FC, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { useDispatch } from "react-redux";
import {
    clearGeneratedDocumentAction,
    viewGeneratedDocumentAction,
} from "../../actions";
import { PdfPreview } from "../../../../components/pdf-preview";
import { makeStyles } from "@material-ui/core/styles";

interface TriggerProps {
    onClick: () => void;
}

interface Props {
    trigger: (props: TriggerProps) => React.ReactNode;
    approvalId: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            minWidth: "720px"
        },
        title: {
            textAlign: "center",
        },
    })
);

export const ApprovedDocumentDialog: FC<Props> = ({ trigger, approvalId }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
        dispatch(viewGeneratedDocumentAction(approvalId));
    };

    const handleClose = () => {
        dispatch(clearGeneratedDocumentAction());
        setOpen(false);
    };

    return (
        <>
            {trigger({ onClick: handleClickOpen })}

            {open && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth={"lg"}
                    onClose={handleClose}
                >
                    <DialogTitle className={classes.title}>
                        Signed Document Preview
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <PdfPreview />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
