import React, { useEffect } from "react";
import {
    Box,
    Card,
    createStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { listedApprovalsSelector, requestApprovalsAction } from "../../index";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ApprovalActions } from "../../components/approval-actions";
import { ApprovalStatusLabel } from "../../components/approval-status-label";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

export const Documents = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const approvals = useSelector(listedApprovalsSelector);

    useEffect(() => {
        dispatch(requestApprovalsAction());
    }, [dispatch]);

    return (
            <Card className={clsx(classes.root, "className")}>
                <PerfectScrollbar>
                    <Box minWidth={1050}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>From</TableCell>
                                    <TableCell>To</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {approvals.map((approval) => (
                                    <TableRow hover key={approval.id}>
                                        <TableCell>
                                            <Box
                                                alignItems="center"
                                                display="flex"
                                            >
                                                <Typography
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {approval.name}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{approval.from}</TableCell>
                                        <TableCell>{approval.to}</TableCell>
                                        <TableCell>
                                            <ApprovalStatusLabel approval={approval} />
                                        </TableCell>
                                        <TableCell>
                                            <ApprovalActions approval={approval} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </PerfectScrollbar>
            </Card>
    );
};
