import React from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../../../components/page";
import { Documents } from "./documents";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F4F6F8",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}));

const ApprovalListView = () => {
    const classes = useStyles();

    return (
        // @ts-ignore
        <Page className={classes.root} title="Timesheets">
            <Container maxWidth={"lg"}>
                <Box mt={3}>
                    <Documents />
                </Box>
            </Container>
        </Page>
    );
};

export default ApprovalListView;
