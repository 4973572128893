import { useEffect } from "react";
import {
    paginatorNumberOfPagesAction,
    paginatorNumberOfPagesSelector,
    paginatorPageNumberAction,
    paginatorPageNumberSelector,
    paginatorResetAction,
} from "../../modules/approval";
import { useDispatch, useSelector } from "react-redux";

export interface Paginator {
    numPages: number;
    pageNumber: number;
    setNumberOfPages: (numberOfPages: number) => void;
    setPageNumber: (page: number) => void;
}

export const usePaginator = (): Paginator => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(paginatorResetAction());
        };
    }, [dispatch]);

    return {
        numPages: useSelector(paginatorNumberOfPagesSelector),
        pageNumber: useSelector(paginatorPageNumberSelector),
        setNumberOfPages: (numberOfPages) => {
            dispatch(paginatorNumberOfPagesAction(numberOfPages));
        },
        setPageNumber: (page: number) => {
            dispatch(paginatorPageNumberAction(page));
        },
    };
};
