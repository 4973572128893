import {colors, createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(',')
    },
    overrides: {
        MuiTableCell: {
            root: {
                padding: '8px 10px',
            },
        },
    },
    palette: {
        background: {
            default: colors.common.white,
            paper: colors.common.white,
        },
        primary: {
            main: "#2b2b2b",
        },
        secondary: {
            main: "#f8bc2e",
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
        },
    },
});

export default theme
