export interface Notification {
    message: string;
    key: number;
    severity: NotificationSeverity;
    dismissed?: boolean;
    action?: any;
}

export enum NotificationSeverity {
    success = "success",
    error = "error",
    warning = "warning",
    info = "info",
}

export type NotificationState = Notification[];

export enum NotificationKey {
    //thousand increments was chosen because we need a key that does not start with 0, which is the default with implicit enums
    SIGNATURE = 1000,
    INITIALS = 2000,
    POSITION_SIGNATURE = 3000,
    POSITION_INITIALS = 4000,
}
