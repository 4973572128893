import { createReducer } from "@reduxjs/toolkit";
import {
    paginatorNumberOfPagesAction,
    paginatorPageNumberAction,
    paginatorResetAction,
} from "../actions";
import { Paginator } from "../interfaces";

export const initialState = { numberOfPages: 1, pageNumber: 1 };

export const paginatorReducer = createReducer<Paginator>(
    initialState,
    (builder) => {
        builder
            .addCase(paginatorNumberOfPagesAction, (state, action) => {
                state.numberOfPages = action.payload;
            })
            .addCase(paginatorPageNumberAction, (state, action) => {
                state.pageNumber = action.payload;
            })
            .addCase(paginatorResetAction, (state, action) => {
                return { ...initialState };
            });
    }
);
