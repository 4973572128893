import { call, cancel, debounce, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import {
    beginGenerateDocumentAction,
    endGeneratedDocumentAction,
    endInitialsAction,
    endSignatureAction,
    initialsPositionAction,
    initialsSelector,
    selectedApprovalSelector,
    signaturePositionAction,
    signatureSelector,
    startSigningAction,
    userInitialsPositionSelector,
    userSignaturePositionSelector,
} from '../../modules/approval'
import { applySignaturesOnPdf } from '../../utils/apply-signatures-on-pdf'
import { AppConfig } from '../../config'
import { notifySuccessAction } from '../../modules/notifications'
import { NotificationKey } from '../../modules/notifications/interfaces'
import { POSITION } from '../../components/choose-signature/button'
import { Approval } from '../../modules/approval/interfaces'

const createSignedPdf = (
    pdf64: string,
    signature64: string,
    initials64: string,
    signaturePosition: POSITION,
    initialsPosition: POSITION,
) => applySignaturesOnPdf(pdf64, signature64, initials64, signaturePosition, initialsPosition)

function* watchStartSignature() {
    let signatureTasks: any[] = []

    while (yield take(startSigningAction.type)) {
        for (const signatureTask of signatureTasks) {
            yield cancel(signatureTask)
        }

        signatureTasks = [yield fork(watchEndSignature), yield fork(watchEndInitials)]
    }
}

function* watchEndSignature() {
    yield debounce(AppConfig.signatureDebounceThresholdMs, endSignatureAction.type, applySignature, [
        {
            key: NotificationKey.SIGNATURE,
            message: 'Signature was applied on document successfully.',
        },
    ])
}

function* applySignature(notificationSuccess: Parameters<typeof notifySuccessAction>) {
    const approval = (yield select(selectedApprovalSelector)) as Approval

    if (approval && approval.originalDocument) {
        const pdf64 = `data:${approval.originalDocument.mimeType},${approval.originalDocument.uri}`

        yield put(beginGenerateDocumentAction())
        yield put(
            endGeneratedDocumentAction(
                yield call(
                    createSignedPdf,
                    pdf64,
                    yield select(signatureSelector),
                    yield select(initialsSelector),
                    yield select(userSignaturePositionSelector),
                    yield select(userInitialsPositionSelector),
                ),
            ),
        )
        yield put(notifySuccessAction(...notificationSuccess))
    }
}

function* watchEndInitials() {
    yield debounce(AppConfig.signatureDebounceThresholdMs, endInitialsAction.type, applySignature, [
        {
            key: NotificationKey.INITIALS,
            message: 'Initials were applied on document successfully.',
        },
    ])
}

function* signaturePositionChange() {
    yield call(applySignature, [
        {
            key: NotificationKey.POSITION_SIGNATURE,
            message: 'Signature position was applied on document successfully.',
        },
    ])
}

function* initialsPositionChange() {
    yield call(applySignature, [
        {
            key: NotificationKey.POSITION_INITIALS,
            message: 'Initials position was applied on document successfully.',
        },
    ])
}

export function* watchSignatureSaga() {
    yield fork(watchStartSignature)
    yield fork(function* () {
        yield takeEvery(signaturePositionAction.type, signaturePositionChange)
    })
    yield fork(function* () {
        yield takeEvery(initialsPositionAction.type, initialsPositionChange)
    })
}
