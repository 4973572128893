import {createServer} from "miragejs";
import {getApiBaseUrl, isSandboxMode} from "./env";
import approvalsApiResponse from "./data/approvals.json";
import newApprovalApiResponse from "./data/new-approval.json";
import approvedApprovalApiResponse from "./data/approved-approval.json";

const apiServer = () => {
    createServer({
        routes() {
            this.get(
                `${getApiBaseUrl()}/approvals`,
                () => approvalsApiResponse
            );
            this.get(`${getApiBaseUrl()}/approvals/:id`, (schema, request) => {
                const id = request.params.id;

                const approval = id === "0803b555-2221-4fab-80b2-14350aef67fd"
                    ?  {...approvedApprovalApiResponse}
                    : {...newApprovalApiResponse}

                approval['@id'] =`/api/approvals/${id}`;

                return approval
            });
            this.put(
                `${getApiBaseUrl()}/approvals/:id/approve`,
                (schema, request) => {
                    const id = request.params.id;
                    const body = JSON.parse(request.requestBody);

                    return {
                        ...newApprovalApiResponse,
                        "@id": `/api/approvals/${id}`,
                        status: "approved",
                        signedDocument: {
                            mimeType: "application/pdf;base64",
                            uri: body.signedDocument,
                        },
                    };
                }
            );
        },
    });
};

if (isSandboxMode()) {
    apiServer();
}
