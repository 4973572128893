import React, { FC, memo } from "react";
import { createStyles, Paper, Theme } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { Paginator } from "./paginator";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pagination: {
            display: "inline-flex",
        },
        paper: {
            padding: theme.spacing(2),
            margin: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
            overflow: "auto",
        },
    })
);

interface Props {
    paginator: Paginator;
}

export const Pager: FC<Props> = memo(
    ({ paginator: { numPages, pageNumber, setPageNumber } }) => {
        const classes = useStyles();

        if (numPages === null || numPages <= 0) {
            return null;
        }

        const onPaginationChange = (e: any, page: number) => {
            setPageNumber(page);
        };

        return (
            <Paper className={classes.paper}>
                <Pagination
                    className={classes.pagination}
                    count={numPages}
                    color="primary"
                    page={pageNumber}
                    onChange={onPaginationChange}
                />
            </Paper>
        );
    }
);
