import { useState } from "react";

export interface Dragger {
    x: number;
    y: number;
    setX: (x: number) => void;
    setY: (y: number) => void;
}

export const useDragger = (): Dragger => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    return {
        x,
        y,
        setX: (x) => setX(x),
        setY: (y) => setY(y),
    };
};
