import React, { FC, forwardRef } from "react";
import { Helmet } from "react-helmet";

interface Props {
    children: any;
    title: string;
}

const Page: FC<Props> = forwardRef(({ children, title = "", ...rest }, ref) => (
    // @ts-ignore
    <div ref={ref} {...rest}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </div>
));

export default Page;
