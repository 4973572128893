import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectedApprovalSelector } from "../../selectors";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IconSize, LightBulbIcon } from "../../../../components/icons";

const useStyles = makeStyles(() => ({
    alertList: {
        listStyleType: "none",
    },
}));

export const Header: FC = () => {
    const classes = useStyles();
    const selectedApproval = useSelector(selectedApprovalSelector);
    return (
        <Box mb={3}>
            <Typography color="textPrimary" variant="h5" gutterBottom={true}>
                Approve Timesheet for « {selectedApproval?.name} »
            </Typography>
            <Alert icon={<LightBulbIcon iconSize={IconSize.MEDIUM} />} severity="warning">
                <ul className={classes.alertList}>
                    <li>
                        Your <strong>signature</strong> will be applied on the{" "}
                        <strong>last page</strong> of the document, at the{" "}
                        <strong>bottom left</strong>.
                    </li>
                    <li>
                        Your <strong>initials</strong> will be applied on{" "}
                        <strong>each</strong> page, at the{" "}
                        <strong>bottom right</strong>.
                    </li>
                </ul>
            </Alert>
        </Box>
    );
};
