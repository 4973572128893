import { createReducer } from "@reduxjs/toolkit";
import { ApprovalsByIdState, ApprovalsState } from "../../../redux/state";
import {
    approveSuccessAction,
    loadApprovalSuccessAction,
    requestApprovalsSuccessAction,
    requestApprovalSuccessAction,
    resetSelectedApprovalAction,
} from "../actions";

export const approvalsReducer = createReducer<ApprovalsState>(
    { listed: [], byId: {}, selected: null } as ApprovalsState,
    (builder) => {
        builder
            .addCase(requestApprovalsSuccessAction, (state, action) => {
                const approvalsById: ApprovalsByIdState = {};
                const listed: string[] = [];
                action.payload.forEach((approval) => {
                    approvalsById[approval.id] = approval;
                    listed.push(approval.id);
                });

                state.byId = approvalsById;
                state.listed = listed;
            })
            .addCase(loadApprovalSuccessAction, (state, action) => {
                state.selected = action.payload;
            })
            .addCase(approveSuccessAction, (state, action) => {
                state.byId[action.payload.id] = action.payload;
            })
            .addCase(requestApprovalSuccessAction, (state, action) => {
                state.byId[action.payload.id] = action.payload;
            })
            .addCase(resetSelectedApprovalAction, (state, action) => {
                state.selected = null;
            });
    }
);
