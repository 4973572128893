import React, { FC } from "react";
import { Approval, ApprovalStatus } from "../interfaces";
import { Chip, Fab } from "@material-ui/core";
import ApproveIcon from "@material-ui/icons/BorderColor";
import { ShowApprovedDocumentButton } from "./show-approved-document-button";
import ErrorIcon from "@material-ui/icons/Error";

export const ApprovalActions: FC<{ approval: Approval }> = ({ approval }) => {
    switch (approval.status) {
        case ApprovalStatus.new:
            return (
                <Fab
                    size={"small"}
                    title={"Approve"}
                    style={{
                        background:
                            "linear-gradient(45deg, #2A399F 20%, #525BA2 90%)",
                        color: "white",
                    }}
                    href={`/approval/${approval.id}`}
                >
                    <ApproveIcon />
                </Fab>
            );
        case ApprovalStatus.approved:
            return <ShowApprovedDocumentButton approvalId={approval.id} />;
        default:
            return (
                <Chip
                    icon={<ErrorIcon />}
                    label={`Unhandled « ${approval.status} » status`}
                    clickable
                    color="primary"
                />
            );
    }
};
