import React, { FC } from "react";
import { createStyles, Icon, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

export interface Props {
    disabled?: boolean;
    label: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            width: "calc(100% - 16px)",
        },
        enabledButton: {
            background: "linear-gradient(45deg, #1e6903 20%, #238200 90%)",
        },
    })
);

export const ConfirmButton: FC<Props> = ({
    disabled = false,
    label,
    onClick,
}) => {
    const classes = useStyles();

    return (
        <Button
            variant={"contained"}
            color={"primary"}
            size={"large"}
            startIcon={<Icon>check</Icon>}
            fullWidth={true}
            className={classNames(classes.button, {
                [classes.enabledButton]: !disabled,
            })}
            onClick={() => onClick && onClick()}
            disabled={disabled}
        >
            {label}
        </Button>
    );
};
