import React, { FC, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import ReactDraggable, { DraggableData, DraggableEvent } from "react-draggable";
import { Dragger } from "./dragger";

interface Props {
    children: any;
    dragger: Dragger;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        draggable: {
            cursor: "move",
            boxShadow: "0 0 10px",
            display: "inline-block",
        },
    })
);

export const Draggable: FC<Props> = ({
    children,
    dragger: { x, y, setX, setY },
}) => {
    const classes = useStyles();
    const dragHandleRef = useRef(null);

    const onDrag = (event: DraggableEvent, data: DraggableData) => {
        setX(data.x);
        setY(data.y);
    };

    return (
        <ReactDraggable
            position={{ x: x, y: y }}
            onDrag={onDrag}
            nodeRef={dragHandleRef}
        >
            <div className={classes.draggable} ref={dragHandleRef}>
                {children}
            </div>
        </ReactDraggable>
    );
};
