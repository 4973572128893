import { createAction } from "@reduxjs/toolkit";
import { Notification } from "./interfaces";

export const addNotificationAction = createAction<Notification>("addNotification");
export const removeNotificationAction = createAction<{key: number}>("removeNotification");
export const dismissNotificationAction = createAction<{key: number}>("dismissNotification");

type NotificationPayload = Pick<OptionalExceptFor<Notification, "message">, "key" | "message">

export const notifySuccessAction = createAction<NotificationPayload>("notifySuccess");
export const notifyInfoAction = createAction<NotificationPayload>("notifyInfo");
export const notifyWarningAction = createAction<NotificationPayload>("notifyWarning");
export const notifyErrorAction = createAction<NotificationPayload>("notifyError");

