import { Icon } from "@material-ui/core";
import React, { FC } from "react";
import { fontSizeMapping, IconSize } from "./sizing";

interface Props {
    iconSize?: number;
    name: string;
    src: string;
}

export const ImageIcon: FC<Props> = ({name, iconSize, src}) => {
    const size = iconSize || IconSize.SMALL

    return (
        <Icon fontSize={fontSizeMapping(size)}>
            <img
                alt={`${name}-icon`}
                src={src}
                height={size}
                width={size}
            />
        </Icon>
    );
}
