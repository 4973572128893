import React, { useEffect } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "../../../../components/page";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { loadApprovalAction } from "../../actions";
import { PdfPreview } from "../../../../components/pdf-preview";
import { EnterSignaturesView } from "./enter-signatures-view";
import { Header } from "./header";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F4F6F8",
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const ApproveView = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(loadApprovalAction(id));
    }, [dispatch, id]);

    return (
        // @ts-ignore
        <Page className={classes.root} title="Timesheets">
            <Container maxWidth={"lg"}>
                <Box mt={3}>
                    <Grid container>
                        <Grid item lg={12}>
                            <Header />
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <PdfPreview />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <EnterSignaturesView />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};

export default ApproveView;
