import { CircularProgress, Fab } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { documentIsGeneratingSelector } from "../selectors";
import ViewIcon from "@material-ui/icons/Description";
import { ApprovedDocumentDialog } from "../views/approval-list-view/approved-document-dialog";

interface Props {
    approvalId: string;
}

export const ShowApprovedDocumentButton: FC<Props> = ({ approvalId }) => {
    const documentIsGenerating = useSelector(documentIsGeneratingSelector);

    return (
        <ApprovedDocumentDialog
            trigger={({ onClick }) => (
                <Fab
                    size={"small"}
                    title={"Show"}
                    onClick={onClick}
                    aria-busy={documentIsGenerating}
                >
                    {documentIsGenerating && (
                        <CircularProgress
                            style={{ color: "black" }}
                            size={20}
                        />
                    )}
                    {!documentIsGenerating && <ViewIcon />}
                </Fab>
            )}
            approvalId={approvalId}
        />
    );
};
