import React, { FC } from "react";
import { ImageIcon } from "./icon";
import HeartImg from "./images/heart.svg";
import LightBulbImg from "./images/lightbulb.svg";
import { ReactComponent as SignatureLayoutBottomRight } from "./images/signature-bottom-right.svg";
import { ReactComponent as SignatureLayoutBottomLeft } from "./images/signature-bottom-left.svg";
import { ReactComponent as SignatureLayoutTopRight } from "./images/signature-top-right.svg";
import { ReactComponent as SignatureLayoutTopLeft } from "./images/signature-top-left.svg";
import { IconSize } from "./sizing";

export { IconSize } from "./sizing";

interface IconProps {
    iconSize: IconSize;
}

export const HeartIcon: FC<IconProps> = ({ iconSize }) => (
    <ImageIcon name={"heart"} src={HeartImg} iconSize={iconSize} />
);
export const LightBulbIcon: FC<IconProps> = ({ iconSize }) => (
    <ImageIcon name={"light-bulb"} src={LightBulbImg} iconSize={iconSize} />
);

const signatureLayoutSize = { width: 75, height: 200 };
export const SignatureLayoutBottomLeftIcon: FC = () => (
    <SignatureLayoutBottomLeft {...signatureLayoutSize} />
);
export const SignatureLayoutBottomRightIcon: FC = () => (
    <SignatureLayoutBottomRight {...signatureLayoutSize} />
);
export const SignatureLayoutTopRightIcon: FC = () => (
    <SignatureLayoutTopRight {...signatureLayoutSize} />
);
export const SignatureLayoutTopLeftIcon: FC = () => (
    <SignatureLayoutTopLeft {...signatureLayoutSize} />
);
