import React from "react";
import MainLayout from "./layout/MainLayout";
import ApprovalListView from "./modules/approval/views/approval-list-view";
import NotFoundView from "./modules/approval/views/errors/not-found-view";
import ApproveView from "./modules/approval/views/approve-view";

const routes = [
    {
        path: "/",
        element: <MainLayout />,
        children: [
            { path: "404", element: <NotFoundView /> },
            { path: "/", element: <ApprovalListView /> },
            { path: "approval/:id", element: <ApproveView /> },
            { path: "*", element: <NotFoundView /> },
        ],
    },
];

export default routes;
