import React, { FC } from "react";
import { SnackbarProvider } from "notistack";
import { AppConfig } from "../config";

const notificationsConfig = AppConfig.notifications;

export const Notifications: FC = ({children}) => {
    return <SnackbarProvider
        maxSnack={5}
        preventDuplicate
        autoHideDuration={notificationsConfig.displayDurationMs}
        transitionDuration={{
            enter: notificationsConfig.enterDurationMs,
            exit: notificationsConfig.exitDurationMs,
        }}
    >
        {children}
    </SnackbarProvider>

}
