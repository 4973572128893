import { combineReducers } from 'redux'
import { State } from './state'
import { approvalsReducer, initialsReducer, signaturePadReducer, signatureReducer } from '../modules/approval/reducers'
import { notificationsReducer } from '../modules/notifications'
import { paginatorReducer } from '../modules/approval/reducers/paginators'

export const rootReducer = combineReducers<State>({
    approvals: approvalsReducer,
    signaturePad: signaturePadReducer,
    user: combineReducers({
        signature: signatureReducer,
        initials: initialsReducer,
    }),
    notifications: notificationsReducer,
    paginator: paginatorReducer,
})
