import { Signature } from '../../../../components/signature'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    approveAction,
    endInitialsAction,
    endSignatureAction,
    initialsPositionAction,
    resetSelectedApprovalAction,
    resetSignaturePadAction,
    signaturePositionAction,
    startSigningAction,
} from '../../actions'
import {
    isApprovalReadySelector,
    selectedApprovalSelector,
    userInitialsDataUrlSelector,
    userInitialsPositionSelector,
    userSignatureDataUrlSelector,
    userSignaturePositionSelector,
} from '../../selectors'
import { ConfirmButton } from '../../../../components/confirm-button'
import { ApprovalStatus } from '../../interfaces'
import { Navigate } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { usePaginator } from '../../../../components/pagination/paginator'

export const EnterSignaturesView = () => {
    const dispatch = useDispatch()
    const signatureDataUrl = useSelector(userSignatureDataUrlSelector)
    const initialsDataUrl = useSelector(userInitialsDataUrlSelector)
    const selectedApproval = useSelector(selectedApprovalSelector)
    const isApprovalReady = useSelector(isApprovalReadySelector)
    const signaturePosition = useSelector(userSignaturePositionSelector)
    const initialsPosition = useSelector(userInitialsPositionSelector)
    const paginator = usePaginator()

    useEffect(() => {
        return () => {
            dispatch(resetSignaturePadAction())
            dispatch(resetSelectedApprovalAction())
        }
    }, [dispatch])

    return (
        <Grid container>
            {selectedApproval?.status === ApprovalStatus.approved && <Navigate to={'/'} />}
            <Grid item lg={12} sm={6} xs={12}>
                <Signature
                    title="Signature"
                    initialSignatureDataUrl={signatureDataUrl}
                    currentPosition={signaturePosition}
                    onSignStart={() => dispatch(startSigningAction())}
                    onSignComplete={(dataUrl: string | null) => dispatch(endSignatureAction(dataUrl))}
                    onPositionChange={(position) => dispatch(signaturePositionAction(position))}
                />
            </Grid>

            {paginator.numPages > 1 && (
                <Grid item lg={12} sm={6} xs={12}>
                    <Signature
                        title="Initials"
                        initialSignatureDataUrl={initialsDataUrl}
                        currentPosition={initialsPosition}
                        onSignStart={() => dispatch(startSigningAction())}
                        onSignComplete={(dataUrl: string | null) => dispatch(endInitialsAction(dataUrl))}
                        onPositionChange={(position) => dispatch(initialsPositionAction(position))}
                    />
                </Grid>
            )}

            <ConfirmButton
                label="Approve"
                disabled={!isApprovalReady}
                onClick={() => selectedApproval && dispatch(approveAction(selectedApproval.id))}
            />
        </Grid>
    )
}
