export enum IconSize {
    SMALL= 25,
    MEDIUM = 40,
    LARGE = 65,
}

export const fontSizeMapping = (size: IconSize) => {
    switch(size) {
        case IconSize.SMALL:
            return "small"
        case IconSize.MEDIUM:
        case IconSize.LARGE:
            return "large"
    }

    return "default"
}
